import {
  CordataExpression,
  CordataManifestation,
  CordataSearchWorksResponse,
  CordataWork,
} from "@biblioteksentralen/bmdb-search-client";
import { formatIsbdTitle } from "@biblioteksentralen/cordata";

export const getWorkData = (workResult: CordataSearchWorksResponse["results"][number]) => {
  const { work } = workResult;

  const representativeManifestationTriple = getRepresentativeManifestationTriple(workResult);

  if (!representativeManifestationTriple)
    throw new Error(`No representative manifestation triple found for ${work.id}`);

  return {
    representativeManifestation: representativeManifestationTriple.manifestation,
    imageUrl: chooseCoverImageQuality(representativeManifestationTriple.manifestation.coverImage, "thumbnail"),
    mainContributor: getMainContributor(representativeManifestationTriple),
    year: (work.workYear ?? representativeManifestationTriple.manifestation.publicationYear)?.toString(),
    formattedTitle: formatIsbdTitle(
      representativeManifestationTriple.manifestation.title ? representativeManifestationTriple.manifestation : work
    ),
    isbn: representativeManifestationTriple.manifestation.isbn[0],
    genres: work.genres,
    subjects: work.subjects,
    summary: work.summary,
  };
};

type RepresentativeManifestationTriple = {
  work: CordataWork;
  expression: CordataExpression;
  manifestation: CordataManifestation;
};

const getRepresentativeManifestationTriple = (
  workResult: CordataSearchWorksResponse["results"][number]
): RepresentativeManifestationTriple | undefined => {
  const { work, representativeManifestationId } = workResult;
  return work.expressions
    ?.flatMap((expression) =>
      [...(expression.manifestations ?? []), ...(expression.aggregateManifestations ?? [])].flatMap(
        (manifestation) => ({ work, expression, manifestation })
      )
    )
    .find(({ manifestation }) => representativeManifestationId && manifestation.id === representativeManifestationId);
};

const qualities = ["original", "thumbnail", "blur"] as const;
type CoverImageQuality = (typeof qualities)[number];
const chooseCoverImageQuality = (url: string | undefined, quality: CoverImageQuality): string | undefined =>
  url?.replace(new RegExp(`\\/(?:${qualities.join("|")})\\.jpg$`), `/${quality}.jpg`);

const getMainContributor = ({ work, expression, manifestation }: RepresentativeManifestationTriple) =>
  [...work.contributors, ...expression.contributors, ...manifestation.contributors].find(
    ({ isMainContributor }) => isMainContributor
  );
