import {
  DefaultBmdbSearchClient,
  DefaultBmdbSearchClientParams,
  createBmdbSearchClient,
} from "@biblioteksentralen/bmdb-search-client";
import { Site } from "@libry-content/types";

type SearchIntegrationEnvironment = NonNullable<Site["librarySystem"]>["searchIntegration"];

type SiteSearchConfig =
  | {
      isSearchIntegrationEnabled: true;
      searchIntegrationEnvironment: SearchIntegrationEnvironment;
      searchApiHost: string;
      searchApiUrl: string;
      libraryCatalogueId: string;
    }
  | {
      isSearchIntegrationEnabled: false;
      searchIntegrationEnvironment: null;
      searchApiHost: null;
      searchApiUrl: null;
      libraryCatalogueId: null;
    };

export type BmdbFetcherParams<TOperation extends keyof DefaultBmdbSearchClient> = {
  operation: TOperation;
  params: DefaultBmdbSearchClientParams[TOperation];
  siteSearchConfig?: SiteSearchConfig;
  useGlobalCatalogue?: boolean;
};

export const bmdbFetcher = async <TOperation extends keyof DefaultBmdbSearchClient>({
  operation,
  params,
  siteSearchConfig,
  useGlobalCatalogue = false,
}: BmdbFetcherParams<TOperation>) => {
  const catalogueId = siteSearchConfig && getCatalogueId({ siteSearchConfig, useGlobalCatalogue });
  if (!catalogueId) {
    console.warn("Search integration not implemented for this site");
    return undefined;
  }

  const client = getBmdbSearchClient({ catalogueId, env: siteSearchConfig?.searchIntegrationEnvironment });
  const { data, error } = await client[operation](params);
  if (error) throw error;
  return data;
};

const getBmdbFeatureFlags = (): string[] => ["filmoteket", "music"];

export const getBmdbSearchClient = (ctx: {
  catalogueId: string;
  env?: SiteSearchConfig["searchIntegrationEnvironment"];
}) =>
  createBmdbSearchClient({
    host: getHost(ctx.env),
    catalogueId: ctx.catalogueId,
    clientIdentifier: "libry-content",
    featureFlags: getBmdbFeatureFlags(),
  });

const getHost = (env?: SiteSearchConfig["searchIntegrationEnvironment"]) => {
  switch (env) {
    case "prod":
      return "https://search.data.bs.no";
    case "staging":
      return "https://search.data-staging.bs.no";
    default:
      return undefined;
  }
};

const getCatalogueId = (context: { siteSearchConfig: SiteSearchConfig; useGlobalCatalogue: boolean }) => {
  if (context.useGlobalCatalogue) return "global";
  return context.siteSearchConfig.libraryCatalogueId;
};
